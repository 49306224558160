import { Formik } from "formik";
import { updatePassword } from "../../api/actions/passwordUpdate.js";
import React, { useState, useEffect } from "react";
import Authheader from "../layout/header/autheader";
import { Link } from "gatsby";
import back from "../signup/assets/back.svg";
import "../login/assets/css/styles.scss";

export default function Password() {
  const [userdata, setuserdata] = useState({});
  const [customError, setCustomError] = useState("");
  const [customSuccess, setCustomSecuss] = useState("");
  const [loading, isloading] = useState(false);

  useEffect(() => {
    if (localStorage.user) {
      setuserdata(JSON.parse(localStorage.user));
    }
  }, []);

  return (
    <div>
      <Authheader />
      <div className="login-form account-edit">
        <div className="signup  form">
          <h1>Edit Account</h1>
          <div className="arrowback">
            <Link to="/account">
              {" "}
              <img src={back} alt="back to account" />
            </Link>
          </div>
          <h2>ACCOUNT</h2>
          {userdata.id && (
            <Formik
              initialValues={{
                old_password: "",
                new_password: "",
                confirm_password: "",
              }}
              validate={(values) => {
                const errors = {};

                if (!values.old_password) {
                  errors.old_password = "Required";
                }

                if (!values.new_password) {
                  errors.new_password = "Required";
                } else if (values.new_password.length < 8) {
                  errors.new_password =
                    "password chracter must begreater then seven";
                }

                if (!values.confirm_password) {
                  errors.confirm_password = "Required";
                } else if (values.confirm_password !== values.new_password) {
                  errors.confirm_password = "password not matched";
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                //update form
                isloading(true);
                const result = await updatePassword(values);
                console.log(result);

                //success or failure check
                if (!!result.data && result.data.message) {
                  setCustomSecuss(result.data.message);
                  isloading(false);
                  setTimeout(() => {
                    setCustomSecuss("");
                  }, 5000);
                } else if (
                  !!result.data &&
                  result.data.errors &&
                  result.data.errors.old_password
                ) {
                  setCustomError(result.data.errors.old_password);
                  isloading(false);
                  setTimeout(() => {
                    setCustomError("");
                  }, 5000);
                } else if (
                  !!result.data &&
                  result.data.errors &&
                  result.data.errors.message
                ) {
                  setCustomError(result.data.errors.message);
                  isloading(false);
                  setTimeout(() => {
                    setCustomError("");
                  }, 5000);
                } else {
                  setCustomError("Something went wrong, Kindly try again.");
                  isloading(false);
                  setTimeout(() => {
                    setCustomError("");
                  }, 5000);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="formgroup form-group">
                      <label>
                        OLD PASSWORD
                      <span>
                          {errors.old_password &&
                            touched.old_password &&
                            errors.old_password}
                        </span>
                      </label>
                      <input
                        name="old_password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.old_password}
                        className="form-control "
                      />
                      <label>
                        NEW PASSWORD
                      <span>
                          {errors.new_password &&
                            touched.new_password &&
                            errors.new_password}
                        </span>
                      </label>
                      <input
                        name="new_password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.new_password}
                        className="form-control "
                      />

                      <label>
                        CONFIRM PASSWORD
                      <span>
                          {errors.confirm_password &&
                            touched.confirm_password &&
                            errors.confirm_password}
                        </span>
                      </label>
                      <input
                        name="confirm_password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirm_password}
                        className="form-control "
                      />
                      <div className="error-custom">
                        {" "}
                        {!!customError && customError}
                      </div>
                      <div className="sucess-custom">
                        {!!customSuccess && customSuccess}
                      </div>
                      <button type="submit" class="next">
                        {loading ? "Updating..." : " Update"}
                      </button>
                    </div>
                  </form>
                )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}
