import API from "../utils/axios";
import { getTokenFromLocalStorage } from "../utils/local-storage";

var headers;

export const updatePassword = async (data, id) => {
  const authExist = getTokenFromLocalStorage();

  if (authExist != "") {
    headers = {
      "Content-Type": "application/json",
      authorization: `Token ${authExist} `,
    };
  }
  const promsiecheck = new Promise((res, rej) => {
    API.put(
      `/change-password`,
      {
        old_password: data.old_password,
        new_password: data.new_password,
      },
      {
        headers,
      }
    )
      .then((response) => {
        res(response);
      })
      .catch((err, resp) => {
        res(err.response);
      });
  });

  const final_data = await promsiecheck;
  return final_data;
};
